.scheme {
    position: relative;
    padding-top: $block-padding;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__list {
        position: relative;
        display: flex;
        align-items: flex-start;
        height: 330px;

        @media (max-width: $container-width) {
            flex-wrap: wrap;
            height: auto;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            background: $color-main-400;

            @media (max-width: $container-width) {
                display: none;
            }
        }

        &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(16.6666% - 23px);
            margin-right: 23px;
            text-align: center;

            @media (max-width: $container-width) {
                width: calc(33.3333% - 33px);
                margin-bottom: 60px;
                
                &:nth-of-type(3n+3) {
                    margin-right: 0;
                }
            }

            @media (max-width: $container-width-md) {
                width: calc(50% - 20px);
                margin-right: 20px !important;
                margin-bottom: 30px;

                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -22px;
                left: 50%;
                transform: translateX(-50%);
                width: 14px;
                height: 14px;
                background: $color-main-400;
                border-radius: 50%;
                border: 2px solid #fff;

                @media (max-width: $container-width) {
                    display: none;
                }
            }

            &-img {
                margin-bottom: 15px;
                order: 1;
                width: 58px;
                height: 58px;

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            &-desc {
                margin-bottom: 10px;
                order: 2;
                line-height: 1.2;
            }

            &-day {
                font-size: 18px;
                font-weight: bold;
                order: 3;
            }

            &:nth-of-type(even) {
                margin-top: auto;

                &:after {
                    bottom: none;
                    top: -21px;
                }


                .scheme__list-item-img {
                    order: 2;

                    @media (max-width: $container-width) {
                        order: 1;
                    }
                }

                .scheme__list-item-desc {
                    margin-bottom: 0;
                    order: 3;

                    @media (max-width: $container-width) {
                        order: 2;
                        margin-bottom: 10px;
                    }
                }

                .scheme__list-item-day {
                    margin-bottom: 10px;
                    order: 1;

                    @media (max-width: $container-width) {
                        order: 3;
                    }
                }
            }
        }
    }
}
