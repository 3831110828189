.heat-system {


    &__list {
        display: none;
        justify-content: flex-start;

        @media (max-width: $container-width-md) {
            flex-wrap: wrap;
        }

        &--half {
            width: 480px;
        }

        &--active {
            display: flex !important;
        }

        &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(33.3333% - 14px);
            min-height: 150px;
            margin-right: 10px;
            border: 1px solid $color-gray-100;
            border-radius: $border-radius;
            cursor: pointer;

            @media (max-width: $container-width-md) {
                width: calc(50% - 5px);
                margin-bottom: 10px;
                min-height: 100px;
            
                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            &--selected {
                border-color: $color-main-400;
            }

            &-img {
                width: 100%;
                height: 100px;  

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: $border-radius $border-radius 0 0;
                }
            }

            &-title {
                height: 100%;
                padding: 7px 20px;
                line-height: 1.2;
                font-size: 14px;
                font-weight: normal;
                background: $color-gray-50;
                border-radius: 0 0 $border-radius $border-radius;
                text-align: center;
            
                @media (max-width: $container-width-md) {
                    padding: 7px 5px;
                    height: auto;
                    min-height: 48px;
                }
            }
        }
    }
}
