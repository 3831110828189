.scroll-top {
    position: fixed;
    left: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    padding: 15px;
    padding-bottom: 17px;
    background: $color-main-400;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;

    img {
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
