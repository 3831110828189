.cases {
    @extend %block-padding;
    position: relative;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
		padding-bottom: $block-padding-sm;
	}

    .container {
        z-index: 20;
    }

    &__tabs {
        display: flex;
        padding: 0 110px;
        margin-bottom: 40px;

        @media (max-width: $container-width-md) {
            padding: 0 20px;
            flex-wrap: wrap;
            margin-bottom: 10px;
        }

        &-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            margin-right: 20px;
            padding: 5px;
            font-weight: bold;
            line-height: 1.15;
            text-align: center;
            color: $color-title;
            cursor: pointer;
            flex: 1;

            &:last-of-type {
                margin-right: 0;
            }

            @media (max-width: $container-width) {
                font-size: 14px;
            }

            @media (max-width: $container-width-md) {
                width: calc(50% - 20px);
                margin-bottom: 30px;

                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                height: 2px;
                background: #d9d9d9;
            }

            &--active, &:hover {
                &:after {
                    background: $color-main-400;
                }
            }
        }
    }

    &__sliders {

    }

    &__slider {
        width: 100%;
        overflow: hidden;

        .slick-slide {
            display: inline-block;
            margin: 0 10px;
            vertical-align: top;
		}

        &-wrap {
            display: none;
            position: relative;
            padding: 0 100px;
            width: 100%;

            @media (max-width: $container-width-md) {
                padding: 0 20px;
            }

            &--active {
                display: block;
            }
        }

        &-arrow {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            transform: translateY(-50%);
            width: 42px;
            height: 42px;
            cursor: pointer;
            z-index: 20;

            @media (max-width: $container-width-md) {
                width: 20px;
            }

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            &--left {
                left: 20px;

                @media (max-width: $container-width-md) {
                    left: 0;
                }
            }

            &--right {
                right: 20px;

                @media (max-width: $container-width-md) {
                    right: 0;
                }
             
                img {
                    transform: rotate(180deg);
                }
            }
        }

        &-item {
            display: inline-flex !important;
            justify-content: space-between;
            text-align: center;
            flex-wrap: wrap;

            &-col {
                width: calc(30% - 15px);

                @media (max-width: $container-width-md) {
                    width: calc(50% - 10px);
                } 

                &:first-of-type {
                    width: calc(40% - 15px);

                    @media (max-width: $container-width-md) {
                        width: 100%;
                        margin-bottom: 20px;
                    } 

                    .cases__slider-item-img {
                        height: 440px;

                        @media (max-width: $container-width) {
                            height: 320px;
                        }

                        @media (max-width: $container-width-md) {
                            height: 140px;
                        }
                    }
                }
            }

            &-img {
                display: block;
                margin-bottom: 20px;
                width: 100%;
                height: 210px;
                overflow: hidden;
                border-radius: 5px;
                overflow: hidden;

                @media (max-width: $container-width) {
                    height: 150px;
                }

                @media (max-width: $container-width-md) {
                    height: 105px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-title {
                width: 100%;
                position: relative;
                margin-top: 10px;
                text-align: center;
            }
        }
    }
}
