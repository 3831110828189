.calculator {
    @extend %block-padding;
    position: relative;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__wrap {
        display: flex;
        flex-direction: column;
        width: calc(100% - 330px);
        margin-right: 40px;
        padding: 55px 65px;
        box-shadow: 0px 10px 30px 0px rgba(23, 12, 9, 0.05);

        @media (max-width: $container-width) {
            width: 100%;
            margin-right: 0;
        }

        @media (max-width: $container-width-md) {
            padding: 50px 10px;
        }
    }

    &__operator {
        padding-top: 60px;
        width: 290px;
        text-align: center;

        @media (max-width: $container-width) {
            display: none;
        }

        &-img {
            margin-bottom: 20px;

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &-name {
            margin-bottom: 5px;
        }

        &-post {

        }
    }

    &__progress {
        position: relative;
        height: 6px;
        margin: 0 -65px;
        margin-top: -55px;
        margin-bottom: 50px;

        @media (max-width: $container-width-md) {
            margin: 0 -10px;
            margin-top: -50px;
            margin-bottom: 25px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 6px;
            background: $color-main-100;
        }

        &-current {
            @extend %transition;
            
            position: absolute;
            left: 0;
            top: 0;
            height: 6px;
            width: 20px;
            background: $color-main-400; 
        }
    }

    &__steps {
        display: flex;
        margin: 0 auto;
        margin-bottom: 80px;

        @media (max-width: $container-width-md) {
            margin-bottom: 30px;
        }

        &-item {
            position: relative;
            width: 40px;
            height: 40px;
            margin: 0 50px;

            @media (max-width: $container-width-md) {
                width: 36px;
                height: 36px;
                margin: 0 6px;
            }

            &-number {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                padding-top: 1px;
                padding-left: 1px;
                background: #d1d1d1;
                font-size: 22px;
                font-weight: bold;
                color: #fff;
                border-radius: 50%;

                @media (max-width: $container-width-md) {
                    width: 36px;
                    height: 36px;
                    padding-top: 2px;
                    padding-left: 0;
                }
            }

            &-title {
                position: absolute;
                bottom: -32px;
                width: 150px;
                left: 50%;
                transform: translateX(-50%);
                color: $color-gray-300;
                text-align: center;

                @media (max-width: $container-width-md) {
                    display: none;
                }
            }

            &:before {
                content: "";
                position: absolute;
                width: 50px;
                height: 3px;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
                background: #d1d1d1;

                @media (max-width: $container-width-md) {
                    display: none;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 50px;
                height: 3px;
                right: -50px;
                top: 50%;
                transform: translateY(-50%);
                background: #d1d1d1;

                @media (max-width: $container-width-md) {
                    display: none;
                }
            }

            &:first-of-type {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }

            &:last-of-type {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            &--passed {
                &:before, &:after {        
                    background: $color-main-400;
                }

                .calculator__steps-item-number {    
                    background: $color-main-400;
                }
            }

            &--current {
                &:before, &:after {        
                    background: $color-gray-300;
                }

                .calculator__steps-item-number {    
                    background: $color-gray-300;
                }
            }
        }
    }

    &__tabs {


        &-item {
            display: none;
            margin-bottom: 70px;

            &:first-of-type {
                margin-bottom: 0;
            }

            @media (max-width: $container-width-md) {
                margin-bottom: 30px;
            }

            &--active {
                display: block;
            }

            &-title {
                margin-bottom: 40px;
                text-align: center;
                font-weight: normal;

                small {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 70%;
                    color: $color-gray-300;
                }
            }

            &:first-of-type {
                .calculator__tabs-item-title {
                    margin-bottom: 80px;

                    @media (max-width: $container-width-md) {
                        margin-bottom: 30px;
                    }
                }
            }

            &:last-of-type {
                @media (max-width: $container-width-md) {
                    br {
                        display: none;
                    }

                    small {
                        display: block;
                    }
                }
            }

            &-hint {
                width: 100%;
                max-width: 340px;
                margin: 0 auto;
                margin-top: 50px;
                font-size: 14px;
                color: $color-gray-300;
                text-align: center;
                
                @media (max-width: $container-width-md) {
                    margin-top: 35px;
                }

                &--wide {
                    max-width: 420px;
                }
            }
        }
    }

    &__prev {
        display: none;
    }

    &__next {
        display: none;
    }

    .calculator__objects {

        .objects__list-item {
            @extend %transition;
            
            padding-top: 20px;
            margin-bottom: 40px;
            cursor: pointer;
            border: 1px solid transparent;

            @media (max-width: $container-width-md) {
                width: calc(50% - 5px);
                margin-right: 10px;
                margin-bottom: 10px;
                padding-top: 10px;

                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            &--no-mb {
                margin-bottom: 0;
            }
        }

        .objects__list-item-title {
            @extend %transition;
            width: 100%;

            @media (max-width: $container-width-md) {
                font-size: 16px;
            }
        }

        .objects__list-item-img {
            width: 140px;
            height: 140px;

            @media (max-width: $container-width-md) {
                width: 110px;
                height: 110px;
            }
        }
    }

    input[type="radio"]:checked + .objects__list-item,
    .objects__list-item:hover {
        border: 1px solid $color-main-400;
        border-radius: $border-radius;

        .objects__list-item-title {
            background: $color-main-400 !important;
            color: #fff;
        }
    }

    .input--range {


        @media (max-width: $container-width-md) {
            width: 75%;
        }
    }
}
