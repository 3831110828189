.header {
    position: relative;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    padding-top: 146px;
    height: 100vh;
    min-height: 790px;
    max-height: 900px;

    @media (max-width: $container-width-md) {
        padding-top: 95px;
        padding-bottom: 50px;
        min-height: 0;
        max-height: none;
        height: auto;
        background: #482c2d;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 146px;
        right: 0;
        bottom: 0;    
        background: url('../img/bg/header.jpg') center / cover no-repeat;
        
        @media (max-width: $container-width-md) {
            display: none;
        }
    }

    .contacts__item:last-of-type {
        margin-bottom: 0;
    }

    &__title {
        margin-top: 12.5vh;
        margin-bottom: 45px;
        width: 100%;
        max-width: 600px;
        color: #fff;
        font-size: 60px;
        font: 500 60px Arial, sans-serif;
        line-height: 59px;

        @media (max-width: $container-width) {
            margin-top: 22vh;
            margin-bottom: 70px;
            max-width: 500px;
            font-size: 50px;
            line-height: 52px;
        }

        @media (max-width: $container-width-md) {
            margin-top: 50px;
            margin-bottom: 35px;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
        }
    }

    &__discount {
        position: absolute;
        top: calc(32% + 73px);
        transform: translateY(-50%);
        right: 0;
        width: 380px;
        padding: 35px 40px;
        background: rgba($color-main-400, 0.85);
        border-radius: $border-radius 0 0 $border-radius;
        box-shadow: 0px 8px 8px 0px rgba(17, 11, 31, 0.15);

        @media (max-width: $container-width) {
            top: calc(30% + 73px);
            padding: 30px;
            width: 270px;
        }

        @media (max-width: $container-width-md) {
            position: static;
            width: 100%;
            max-width: 290px;
            margin: 0 auto;
            margin-top: 10px;
            transform: none;
            border-radius: $border-radius;
            padding: 20px;
            text-align: center;

            img {
                display: none;
            }
        }

        img {
            position: absolute;
            left: 30px;
            top: 0;
            transform: translateY(-50%);
        }

        &-title {
            position: relative;
            margin-bottom: 30px;
            font-weight: bold;
            color: #fff;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -13px;
                width: 100px;
                height: 1px;
                background: #fff;

                @media (max-width: $container-width-md) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &-desc {
            margin-bottom: 25px;
            color: #fff;
            font-size: 17px;

            @media (max-width: $container-width-md) {
                font-size: 14px;
            }
        }
    }

    &__features {
        display: flex;
        width: 100%;
        max-width: 735px;

        @media (max-width: $container-width-md) {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(23% - 27px);
            margin-right: 20px;

            &:nth-of-type(2) {
                width: calc(31% - 27px);

                @media (max-width: $container-width-md) {
                    width: calc(50% - 15px);
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            @media (max-width: $container-width-md) {
                width: calc(50% - 15px);
                margin-right: 0;
                margin-bottom: 40px;

                &:nth-of-type(even) {
                    margin-right: 0;
                }
            }

            &-icon {
                margin-bottom: 15px;
                height: 64px;

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;;
                }
            }

            &-desc {
                font-size: 18px;
                color: #fff;
                line-height: 1.3;
                text-align: center;

                @media (max-width: $container-width-md) {
                    font-size: 14px;
                }
            }
        }
    }

    &__button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 50px;

        @media (max-width: $container-width-md) {
            position: static;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            width: 100%;
            max-width: 320px;
            transform: none;
        }
    }
}
