.navbar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    background: #fff;

    &--fixed {
        position: fixed;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &__heading {
        display: flex;
        align-items: center;

        @media (max-width: $container-width-md) {
            justify-content: space-between;
        }

        &-wrap {
            padding: 15px 0;
        }

        &-info {
            width: 200px;
            font-size: 14px;
            line-height: 18px;

            @media (max-width: $container-width-md) {
                display: none;
            }
        }

        &-logo {
            justify-content: center;
            width: calc(100% - 480px);

            .logo__desc {
                h5 {
                    font-size: 28px;
                    line-height: 1;
                }

                p {
                    font-size: 18px;
                }
            }

            @media (max-width: $container-width) {
                .logo__desc {
                    display: none;
                }
            }

            @media (max-width: $container-width-md) {
                width: calc(100% - 200px);
            }
        }

        &-contacts {
            width: 220px;
            margin-left: auto;

            @media (max-width: $container-width-md) {
                width: 180px;
            }

            .contacts__item {
                font-size: 16px;
                
                @media (max-width: $container-width-md) {
                    font-size: 14px;    
                }
            }
        }
    }

    &__list {
        width: calc(100% - 220px);
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;

        @media (max-width: $container-width) {
            width: 100%;
            text-align: left;
        }

        &-wrap {
            border-top: 1px solid $color-gray-100;
            background: #353535;

            @media (max-width: $container-width-md) {
                display: none;
            }

            .button {
                width: 220px;
                
                @media (max-width: $container-width) {
                    display: none;
                }
            }
        }

        &-item {
            display: inline-block;

            a {
                position: relative;
                display: inline-block;
                padding: 15px;
                height: 56px;
                color: #fff;
                font-size: 16px;
                text-decoration: none;

                @media (max-width: $container-width) {
                    font-size: 14px;
                }

                &:before {
                    @extend %transition;

                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    background: $color-main-400;
                    transform: translateX(-50%);
                }
            }

            &:first-of-type {
                a {
                    padding-left: 0;
                }
            }

            &--active, &:hover {
                a {
                    &:before {
                        width: 40%;
                    }
                }
            }
        }
    }
}
