// Colors
$color-main-100: #fff8f8;
$color-main-300: #ff7478;
$color-main-400: #ff6064;
$color-main-500: #dd5357;

$color-gray-50: #f9f9f9;
$color-gray-100: #e3e3e3;
$color-gray-300: #6e6e6e;
$color-gray-400: #454545;
$color-gray-500: #3a3a3a;

$color-text: $color-gray-400;
$color-title: $color-gray-500;

$color-button: $color-main-400;
$color-button-hover: $color-main-500;

// Fonts
$font-family: "Nunito", sans-serif;

// Fonts -- Paragraphs
$font-weight-p: 300;
$font-size-p: 18px;
$font-size-p-sm: 16px;

// Fonts -- Headlines
$font-weight-h1: normal;
$font-size-h1: 45px;

$font-weight-h2: normal;
$font-size-h2: 35px;

$font-weight-h3: normal;
$font-size-h3: 27px;

$font-weight-h4: bold;
$font-size-h4: 22px;

$font-weight-h5: bold;
$font-size-h5: 20px;

$font-weight-h6: bold;
$font-size-h6: 18px;

// Other
$border-radius: 5px;
$block-padding: 75px;
$block-padding-sm: 50px;
