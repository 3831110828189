.principles {
    @extend %block-padding;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__list {
        margin-bottom: 70px;

        @media (max-width: $container-width-md) {
            margin-bottom: 30px;
        }

        &-item {
            display: flex;
            margin-bottom: 55px;
            box-shadow: 0px 8px 20px 0px rgba(75, 34, 35, 0.02);
            border: 1px solid $color-gray-100;
            border-radius: $border-radius;

            @media (max-width: $container-width-md) {
                flex-wrap: wrap;
                margin-bottom: 30px;
                text-align: center;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &-about {
                display: flex;
                width: 75%;
                padding: 35px;

                @media (max-width: $container-width-md) {
                    flex-wrap: wrap;
                    width: 100%;
                    padding: 20px;
                    padding-bottom: 0;
                }
            }

            &-info {
                width: 39%;
                padding-right: 25px;
                
                @media (max-width: $container-width-md) {
                    width: 100%;
                    margin-bottom: 20px;
                    padding-right: 0;
                }
            }

            &-title {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 22px;
            }

            &-desc {
                display: flex;
                flex-direction: column;
                width: 58%;
                padding-left: 25px;
                justify-content: center;
                padding-top: 36px;

                @media (max-width: $container-width-md) {
                    width: 100%;
                    padding-left: 0;
                    padding-top: 0;
                }

                &-price {
                    margin-top: auto;
                    text-align: right;
                    font-weight: bold;

                    @media (max-width: $container-width-md) {
                        text-align: center;
                        margin-top: 25px;
                        margin-bottom: -10px;
                    }
                }
            }

            &-img {
                width: 100%;
                max-width: 290px;
                
                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &__examples {
        display: flex;
        width: 25%;
        padding: 35px;
        border-left: 1px solid $color-gray-100;
        
        @media (max-width: $container-width-md) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0 10px;
            
            @media (max-width: $container-width-md) {
                margin-bottom: 0;
                padding: 0;
                text-align: center;
            }

            &-title {
                margin-bottom: 15px;
                font-weight: 600;
                line-height: 18px;
                text-align: center;
            }

            &-img {
                display: flex;
                align-items: center;
                height: 100%;

                img {
                    object-fit: contain;
                    width: 100%;
                    max-height: 100%;
                }
            }

            &-price {
                font-weight: bold;

                @media (max-width: $container-width-md) {
                    margin-top: 10px;
                }
            }
        }
    }
}
