.table {
    margin-bottom: 20px;

    table {
        border-collapse: collapse;
        border-style: hidden;
    }

    th, td {
        padding: 8px 10px;
        border: 1px solid $color-gray-100;
        text-align: center;
    }

    th {
        font-size: 16px; 
    }

    td {
        font-size: 18px; 
    }

    &__heading {
        font-size: 20px !important;
    }
}
