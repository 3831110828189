@import "extends";

*, *:before, *:after {
    box-sizing: border-box;
    outline-width: 0;
}

html, body {
    position: relative;
    font: 300 $font-size-p $font-family;
    color: $color-text;
    min-width: 320px;

    @media (max-width: $container-width) {
        font-size: $font-size-p-sm;
    }
}

body {
    background: #fff;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    color: $color-gray-500;
}

h1 {
    font-weight: normal;
    font-size: 45px;
}

h2 {
    font-weight: normal;
    font-size: 35px;
}

h3 {
    font-weight: normal;
    font-size: 27px;
}

h4 {
    font-weight: normal;
    font-size: 22px;
}

h5 {
    font-weight: bold;
    font-size: 20px;
}

h6 {
    font-weight: bold;
    font-size: 18px;
}

p {
    margin: 0;
}

.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.color {
    &--pink {
        color: $color-main-400;
    }
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: bold;
}

.push {
    display: flex;

    &__left {
        margin-right: auto;
    }

    &__right {
        margin-left: auto;
    }
}

.hidden {
    display: none;
}

.body-fixed {
    overflow-y: hidden;
}

a {
    text-decoration: none;
}
