.logo {
    display: flex;
    align-items: center;

    &__img {
        display: flex;
        height: 65px;

        img {
            object-fit: contain;
            max-width: 100%;
            max-height: 70px;
        }
    }

    &__desc {
        padding-left: 30px;
        line-height: 1.2;

        p {
            color: #7d7a7a;
        }
    }
}
