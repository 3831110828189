.video-reviews {
  position: relative;
  background: $color-main-100;
  padding-bottom: 90px;

  @media (max-width: $container-width-md) {
    padding-bottom: 50px;
  }

  .title {
    position: relative;
    z-index: 10;

    @media (max-width: $container-width-md) {
      br {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;

    &-item {
      width: calc(33.33333% - 20px);
      margin-right: 30px;
      margin-bottom: 30px;

      @media (max-width: $container-width-md) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-of-type(3n+3) {
        margin-right: 0;
      }

      &--hidden {
        display: none;
      }

      iframe {
        width: 100%;
        height: 200px;
        margin-bottom: 8px;
        object-fit: contain;
      }

      &-title {
        font-size: 18px;
        line-height: 1.2;
        font-weight: bold;

        @media (max-width: $container-width-md) {
          text-align: center;
        }
      }
    }
  }

  .button {
    @media (max-width: $container-width-md) {
      width: 100%;
    }
  }
}
