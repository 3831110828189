.contacts {


    &__item {
        display: block;
        margin-bottom: 9px;
        color: $color-text;
        text-decoration: none;

        img {
            display: inline-block;
            margin-right: 15px;
            vertical-align: middle;
        }
    }
}
