.objects {
    position: relative;
    padding-top: $block-padding;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    .title {
        margin-bottom: 70px;

        @media (max-width: $container-width-md) {
            margin-bottom: 40px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $container-width-md) {    
            justify-content: space-between;
        }

        &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(33.3333% - 30px);
            //height: 220px;
            margin-right: 44px;
            margin-bottom: 70px;
            //border: 1px solid $color-gray-100;
            //border-radius: $border-radius;
            //box-shadow: 0px 8px 30px 0px rgba(75, 34, 35, 0.05);

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }

            @media (max-width: $container-width-md) {
                width: calc(50% - 15px);
                margin-right: 0;
                margin-bottom: 30px;

                &:nth-of-type(5),
                &:nth-of-type(6) {
                    margin-bottom: 0;
                }
            }

            &-img {
                width: 225px;
                height: 225px;
                max-width: 100%;
                max-height: 100%;
                border-radius: 50%;
                margin-bottom: 20px;

                @media (max-width: $container-width) {
                    width: 170px;
                    height: 170px;
                }

                @media (max-width: $container-width-md) {
                    margin-bottom: 10px;
                    height: 130px;
                }

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            &-title {
                margin-top: auto;
                padding: 9px 20px;
                font-size: 22px;
                font-weight: normal;
                text-align: center;
                //background: $color-gray-50;

                @media (max-width: $container-width-md) {
                    font-size: 20px;
                }
            }
        }
    }
}
