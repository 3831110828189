.guarantees {
    @extend %block-padding;
    position: relative;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__bg {
        position: absolute;
        left: 750px;
        bottom: -$block-padding;
        width: 572px;

        @media (max-width: $container-width) {
            display: none;
        }

        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 740px;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(33.3333% - 10px);
            margin-right: 15px;
            margin-bottom: 70px;

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 40px;
            }

            &-img {
                margin-bottom: 7px;
                width: 64px;
                height: 64px;

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            &-title {
                margin-bottom: 11px;
                text-align: center;
                font-weight: bold;
            }

            &-desc {
                text-align: center;
            }
        }
    }

    &__action {
        width: 100%;
        max-width: 740px;
        text-align: center;
    }

    .button {
        @media (max-width: $container-width-md) {
            width: 100%;
        }
    }
}
