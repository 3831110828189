.title {
    position: relative;
    //display: inline-block;
    margin-bottom: 80px;
    font-size: 40px;
    line-height: 45px;

    @media (max-width: $container-width-md) {
        margin-bottom: 40px;
        font-size: 25px;
        line-height: 1.2;
        text-align: center;
    }

    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 0;
        bottom: -12px;
        width: 30%;
        height: 2px;
        background: $color-main-400;
    }
}
