.stock {
    @extend %block-padding;
    position: relative;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__attention {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        &-icon {
            width: 62px;
            margin-right: 35px;

            @media (max-width: $container-width-md) {
                display: none;
            }
        }

        &-content {
            width: calc(100% - 97px);

            @media (max-width: $container-width-md) {
                width: 100%;
            }

            h4 {
                color: $color-text;
                line-height: 1.2;
            }
        }

        &-title {
            margin-bottom: 10px;
            color: $color-main-400;
        }
    }

    &__wrap {
        position: relative;
        margin-bottom: 100px;

        @media (max-width: $container-width-md) {
            margin-bottom: 40px;
        }
    }

    &__img {
        position: absolute;
        left: -200px;
        top: 0;

        @media (max-width: $container-width) {
            width: 400px;
        }

        @media (max-width: $container-width-md) {
            position: static;
            margin-bottom: 40px;
            width: 100%;
            max-width: 320px;
        }

        img {
            display: block;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__about {
        padding-left: 560px;

        @media (max-width: $container-width) {
            padding-left: 230px;
        }

        @media (max-width: $container-width-md) {
            padding-left: 0;
        }

        h6 {
            margin-bottom: 10px;
        }
    }

    &__title {
        margin-bottom: 20px;
    }

    &__photos {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;

        @media (max-width: $container-width-md) {
            flex-wrap: wrap;
        }

        &-item {
            @extend %transition;

            width: calc(33.3333% - 34px);
            margin-right: 50px;
            box-shadow: 0px 8px 30px 0px rgba(75, 34, 35, 0.05);
            border: 1px solid $color-gray-100;
            border-radius: $border-radius;
            overflow: hidden;

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 20px;
                margin-right: 0;
            }

            &:hover {
                transform: scale(1.25);
            }

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__hint {
        margin-bottom: 40px;

        @media (max-width: $container-width) {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 40px;
        }
    }
}
