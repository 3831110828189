.footer {
    position: relative;
    background: $color-title;

    &__map {
        width: 100%;
        height: 500px;
    }

    &__wrap {
        padding: 60px 0;
    }

    &__company {
        width: 450px;

        @media (max-width: $container-width) {
            width: 100%;
            margin-bottom: 30px;
        }

        &-ogrnip {
            margin-top: 10px;
            color: #cdc7c7;
            font-size: 16px;
        }
    }

    &__logo {

        
        h5 {
            font-size: 20px;
            color: $color-main-100;

            @media (max-width: $container-width-md) {
                display: none;
            }
        }

        p {
            color: #cdc7c7;

            @media (max-width: $container-width-md) {
                display: none;
            }
        }
    }

    &__contacts {
        width: calc(100% - 780px);

        @media (max-width: $container-width) {
            width: 100%;
            margin-bottom: 30px;
        }

        .contacts__item {
            color: $color-main-100;
            margin-bottom: 8px !important;
            font-size: 16px;
        }
    }

    &__nav {
        width: 330px;

        @media (max-width: $container-width) {
            width: 100%;
        }

        &-item {
            display: inline-block;
            width: calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 9px;
            color: $color-main-100;
            text-decoration: none;
            font-size: 16px;

            &:nth-of-type(even) {
                margin-right: 0;
            }
        }
    }
}
