.discount {
    position: relative;
    padding-bottom: $block-padding;

    @media (max-width: $container-width-md) {
		padding-bottom: $block-padding-sm;
    }

    &__bg {
        position: absolute;
        right: 0;
        width: 500px;

        @media (max-width: $container-width) {
            display: none;
        }

        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__wrap {
        width: 100%;
        max-width: 790px;
    }

    &__hint {
        margin-bottom: 30px;
        text-align: center;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        @media (max-width: $container-width-md) {
            flex-wrap: wrap;
            margin-bottom: 30px;
            text-align: center;
        }

        &-icon {
            width: 62px;
            margin-right: 35px;

            @media (max-width: $container-width-md) {
                display: none;
            }
        }

        &-content {
            width: calc(100% - 257px);

            @media (max-width: $container-width-md) {
                width: 100%;
            }

            h4 {
                color: $color-text;
                line-height: 1.2;

                @media (max-width: $container-width-md) {
                    font-size: 16px;
                }
            }
        }

        &-title {
            margin-bottom: 10px;
            color: $color-main-400;
            line-height: 1.15;

            @media (max-width: $container-width-md) {
                font-size: 22px;
            }
        }

        &-percent {
            width: 160px;
            font-size: 70px;
            font-weight: bold;
            color: $color-main-400;
            text-align: right;

            @media (max-width: $container-width-md) {
                margin-top: 0;
                width: 100%;
                text-align: center;
                font-size: 55px;
            }
        }
    }

    .button {
        @media (max-width: $container-width-md) {
            width: 100%;
        }
    }
}
