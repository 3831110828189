.faq {
    @extend %block-padding;
    position: relative;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
        padding-top: 0;
		padding-bottom: $block-padding-sm;
	}

    .container {
        z-index: 20;
    }

    .faq__list-item:nth-of-type(n+7) {
        display: none;
    }

    &--showed {
        .faq__list-item:nth-of-type(n+7) {
            display: flex;
        }
    }

    &__bg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 632px;

        @media (max-width: $container-width-md) {
            display: none;
        }

        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__list {
        width: 100%;
        max-width: 980px;

        &-item {
            display: flex;
            margin-bottom: 70px;

            @media (max-width: $container-width-md) {
                margin-bottom: 30px;
            }

            &--active {
                .faq__list-item-answer {
                    display: block;
                }

                .faq__list-item-readmore {
                    display: none;
                }

                .faq__list-item-status .plus {
                    display: none;
                }
                
                .faq__list-item-status .minus {
                    display: block;
                }
            }

            &-status {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                margin-right: 25px;
                padding-left: 1px;
                padding-bottom: 1px;
                border: 2px solid $color-main-400;
                border-radius: 50%;
                font-weight: bold;
                font-size: 30px;
                text-align: center;
                color: $color-main-400;
                cursor: pointer;

                @media (max-width: $container-width-md) {
                    display: none;
                }

                .minus {
                    display: none;
                }
            }

            &-content {
                width: calc(100% - 60px);

                @media (max-width: $container-width-md) {
                    width: 100%;
                }

                img {
                    @extend %transition;

                    display: block;
                    max-width: 100%;
                    margin: 15px 0;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            &-question {

            }

            &-readmore {
                padding-top: 5px;
                font-weight: bold;
                color: $color-main-400;
                cursor: pointer;
            }

            &-answer {
                display: none;
                padding-top: 30px;
            }
        }
    }

    @media (max-width: $container-width-md) {
        .button {
            margin-bottom: 20px;
            margin-left: 0 !important;
        }
    }
}
