.button {
    @extend %transition;

	display: inline-block;
	padding: 17px 42px;
	background: transparent;
	border: 0;
	box-shadow: none;
	cursor: pointer;
    font: normal 16px $font-family;
    line-height: 18px;
	color: #fff;
	text-decoration: none;
    border-radius: $border-radius;
    
    @media (max-width: $container-width) {
        padding: 15px 30px;
    }

    @media (max-width: $container-width-md) {
        padding: 15px 22px;
    }

    &--bg {
        &-main {
            border: 2px solid $color-main-400;
            background: $color-main-400;

            &:hover {
                background: $color-main-500;
                border-color: $color-main-500;
            }
        }

        &-main-nofill {
            border: 2px solid $color-main-400;
            color: $color-text;
            background: transparent;

            &:hover {
                background: $color-main-400;
                color: #fff;
            }
        }

        &-white-nofill {
            border: 2px solid #fff;
            background: transparent;

            &:hover {
                background: #fff;
                color: $color-text;
            }
        }

        &-gray-nofill {
            color: $color-text;
            border: 2px solid #d1d1d1;
            background: transparent;

            &:hover {
                background: #d1d1d1;
                color: $color-text;
            }
        }
    }

    &--no-rounding {
        border-radius: 0;
    }

    &-center {
        text-align: center;
    }

    &-group {


        .button:not(:first-child) {
            margin-left: 9px;

            @media (max-width: $container-width-md) {
                margin-left: 0;
            }
        }

        .button {
            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
