.team {
    position: relative;
    padding-bottom: $block-padding;
    background: $color-main-100;

    @media (max-width: $container-width-md) {
		padding-bottom: $block-padding-sm;
    }
    
    .title {
        z-index: 10;
    }

    &__list {
        position: relative;
        display: flex;
        z-index: 20;

        @media (max-width: $container-width) {
            flex-wrap: wrap;
        }

        &-item {
            width: calc(25% - 15px);
            margin-right: 20px;
            padding: 25px;
            box-shadow: 0px 8px 30px 0px rgba(75, 34, 35, 0.07);
            border-radius: $border-radius;

            @media (max-width: $container-width) {
                width: calc(50% - 30px);
                margin-bottom: 30px;
                margin-right: 30px;

                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-right: 0;
            }

            &-img {
                margin: -25px -25px 20px -25px;

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: $border-radius $border-radius 0 0;
                }
            }

            &-name {

            }

            &-post {
                margin-bottom: 12px;
                font-size: 14px;
                color: #9d8686;
            }

            &-desc {

            }
        }
    }
}
