.dropdown {
    position: relative;
    z-index: 30;

    &-content {
        position: absolute;
        display: none;
        top: 100%;
        padding: 20px;
        left: 0;

        .heat-system__list {
            padding: 20px;
            margin-left: -20px;
            background: #fff;
            box-shadow: 0px 3px 12px 0px rgba(23, 12, 9, 0.15);
            border-radius: $border-radius;

            &-item {
                @extend %transition;
                
                &:hover {
                    border: 1px solid $color-main-400;
                }
            }
        }
    }

    &:hover {
        > .dropdown-content {
            display: block;
        }
    }
}
