.pop-up {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 10000;

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;	
		background: rgba(#230d0e, 0.7);
	}

	&__panel {
		position: relative;
		width: 100%;
		max-width: 500px;
		background: #fff;
		border-radius: 0px;
		max-height: calc(100vh - 40px);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: $border-radius;

		&--wide {
			max-width: 1200px;

			@media (max-width: 1300px) {
				max-width: none;
				max-height: 100vh;
			}

			.pop-up__panel-content {
				@media (max-width: 1300px) {
					max-height: 100vh;
				}
			}
		}
	
		&-content {	
			padding: 40px 60px;
			overflow-y: auto;
			max-height: calc(100vh - 40px);
		}
	}

	&__close {
		@extend %transition;

		position: absolute;
		top: 45px;
		right: 40px;
		cursor: pointer;
		opacity: 0.7;
		width: 20px;
		height: 20px;

		img {
			display: block;
			object-fit: contain;
			width: 100%;
			height: 100%;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__title {
		margin-bottom: 15px;
		padding-right: 60px;
		font-size: 27px;
		color: $color-title;
		line-height: 30px;
	}

	&__desc {
		width: 85%;
		margin-bottom: 25px;
		font-size: 16px;
		line-height: 1.4;
		color: $color-text;

		a {
			@extend %transition;

			color: darken($color-main-400, 25%);
			text-decoration: none;

			&:hover, &:focus {
				color: $color-main-400;
				text-decoration: underline;
			}
		}
	}

	&__form {
		margin-bottom: 20px;
	}

	.button {
		margin-top: 20px;
	}

	&__subtext {
		font-size: 14px;
		line-height: 1.2;
		color: $color-gray-300;
	}
}
