.faq-title {
    display: inline-block;
    margin-bottom: 70px;
    padding: 20px 40px;
    line-height: 1.35;
    border: 1px solid #ff6064;
    border-radius: $border-radius;

    @media (max-width: $container-width-md) {
        margin-bottom: 40px;
        width: 100%;
    }

    &__mark {
        position: relative;
        font-size: 18px;
        color: $color-main-400;

        @media (max-width: $container-width-md) {
            font-size: 16px;
        }

        img {
            position: absolute;
            width: 50px;
            height: 50px;
            left: -50px;
            top: -32px;
            padding: 10px;
            background: #fff;
        }
    }

    &__question {
        color: $color-gray-300;
        line-height: 1.1;

        @media (max-width: $container-width-md) {
            font-size: 18px;

            br {
                display: none;
            }
        }
    }

    &--pink {
        .faq-title__mark img {
            background: $color-main-100;
        }
    }
}
