.cheaper {
    @extend %block-padding;
    position: relative;
    overflow: hidden;

    @media (max-width: $container-width-md) {
		padding: $block-padding-sm 0;
	}

    &__warning {
        padding-left: 422px;
        padding-bottom: $block-padding;

        @media (max-width: $container-width-md) {
            padding-left: 0;
        }

        &-wrap {
            position: relative;
        }

        &-img {
            position: absolute;
            left: -240px;
            top: 0;
            width: 612px;

            @media (max-width: $container-width-md) {
                position: static;
                width: 100%;
                max-width: 320px;
                margin: 0 auto;
                margin-bottom: 30px;
            }

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &-title {
            position: relative;
            margin-bottom: 50px;

            @media (max-width: $container-width-md) {
                padding-left: 80px;
                font-size: 22px;
            }

            &-icon {
                position: absolute;
                left: -110px;
                top: 50%;
                transform: translateY(-50%);
                width: 70px;
                height: 64px;

                @media (max-width: $container-width-md) {
                    left: 0;
                    width: 60px;
                    height: auto;
                }

                img {
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-item {
            margin-bottom: 40px;

            &-title {
                display: flex;
                margin-bottom: 5px;
                align-items: flex-start;
                line-height: 1.1;

                &-number {
                    margin-top: -5px;
                    min-width: 34px;
                    font-size: 27px;
                    font-weight: bold;
                    color: $color-main-400;
                }
            }

            &:nth-of-type(3) {
                .cheaper__warning-item-title {
                    width: 100%;
                    max-width: 620px;
                }
            }

            &-desc {
                padding-left: 34px;
            }
        }
    }

    &__features {
        position: relative;

        &-bg {
            position: absolute;
            right: -460px;
            bottom: 0;
            width: 768px;
            z-index: -1;
            display: none;

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;

            @media (max-width: $container-width-md) {
                margin-bottom: 0;
            }

            &-item {
                display: flex;
                margin-bottom: 50px;
                align-items: center;
                width: calc(49% - 40px);
                margin-right: 60px;

                @media (max-width: $container-width-md) {
                    width: 100%;
                    margin-right: 0;
                }

                &:nth-of-type(even) {
                    margin-right: 0;
                }

                &-icon {
                    margin-right: 30px;
                    width: 45px;
                    height: 43px;;

                    img {
                        display: block;
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }

                &-title {
                    width: calc(100% - 75px);
                    font-weight: bold;
                }
            }
        }

        h3 {
            @media (max-width: $container-width-md) {
                font-size: 22px;
                text-align: center;
            }
        }
    }
}
