.container {
	position: relative;
	width: 100%;
	max-width: $container-width;
	margin: 0 auto;
	padding: 0 $container-gutter;

	@media (max-width: $container-width) {
		max-width: $container-width-md;
	}

	@media (max-width: $container-width-md) {
		max-width: $container-width-sm;
	}
}
