.input {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    max-width: 320px;

    &__title {
        @extend %transition;
        position: absolute;
        font-size: 14px;
        color: $color-gray-300;
        padding-left: 55px;
        opacity: 0;
        z-index: 10;
        top: 10px;
    }

    img {
        position: absolute;
        left: 17px;
        top: 14px;
        display: block;
        width: 20px;
        height: auto;
    }

    &--active {
        .input__title {
            top: -6px;
            opacity: 1;;
        }
    }

    input, textarea {
        position: relative;
        display: block;
        width: 100%;
        padding: 14px 20px;
        padding-left: 55px;
        background: transparent;
        border: 0;
        border-bottom: 1px solid $color-main-400;
        font-size: 16px;
        font-weight: normal;
        border-radius: 0;
        color: $color-text;
        resize: none;
        z-index: 20;

        &::placeholder {
            color: $color-text;
            opacity: 0.8;
        }
    }

    &--range {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        .input__range-plus,
        .input__range-minus {
            position: absolute;
            right: -26px;
            top: calc(50% - 2px);
            transform: translateY(-50%);
            font-size: 26px;
            font-weight: bold;
            line-height: 0;
            color: $color-main-400;
            cursor: pointer;    
        }

        .input__range-minus {
            right: auto;
            left: -26px;
        }

        .input__range-current {
            position: absolute;
            bottom: -25px;
            width: 100px;
            text-align: center;
            transform: translateX(-50%);
        }

        input[type="range"] {
            -webkit-appearance: none;
            margin: 18px 0;
            padding: 0;
            width: 100%;
            height: 21px;
            border: 0;

            &:focus {
                outline: none;
            }

            &::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                background: #d1d1d1;
            }

            &::-webkit-slider-thumb {
                width: 19px;
                height: 19px;
                background: $color-main-400;
                cursor: pointer;
                -webkit-appearance: none;
                border: 3px solid #fff;
                border-radius: 50%;
                transform: translateY(-9px);
            }

            &:focus::-webkit-slider-runnable-track {
                background: #d1d1d1;
            }

            &::-moz-range-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                background: #d1d1d1;
            }

            &::-moz-range-thumb {
                width: 16px;
                height: 16px;
                background: $color-main-400;
                cursor: pointer;
                -webkit-appearance: none;
                border: 3px solid #fff;
                border-radius: 50%;
            }

            &::-ms-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                background: #d1d1d1;
            }

            &::-ms-fill-lower {
                background: #2a6495;
                border: 0.2px solid #010101;
                border-radius: 2.6px;
                box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            }

            &::-ms-fill-upper {
                background: #3071a9;
                border: 0.2px solid #010101;
                border-radius: 2.6px;
                box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
            }

            &::-ms-thumb {
                width: 16px;
                height: 16px;
                background: $color-main-400;
                cursor: pointer;
                -webkit-appearance: none;
                border: 3px solid #fff;
                border-radius: 50%;
            }

            &:focus::-ms-fill-lower {
                background: #3071a9;
            }
            
            &:focus::-ms-fill-upper {
                background: #367ebd;
            }
        }
    }

    &-group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        .input {
            &:first-of-type {
                margin-right: 20px;
            }

            @media (max-width: $container-width) {
                margin: 0 !important;
                margin-bottom: 20px !important;
                width: 100%;
            }
        }
    }
}

input.error,
textarea.error {
	border-radius: 4px 4px 0 0 !important;
	//border-color: rgb(229, 56, 53) !important;
}

input + label.error,
textarea + label.error {
	background-color: $color-main-500;
	color: #fff;
	display: block;
	font: 300 12px $font-family;
	padding: 2px 4px;
	z-index: 2;
	border-radius: 0 0 4px 4px;
}
